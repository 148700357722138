<template>
  <b-card
    no-body
  >
    <b-table
      striped
      hover
      responsive
      show-empty
      class="position-relative"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :items="items"
      @sort-changed="sortManual"
    >
       <template #cell(no)="data">
        {{ (currentPage - 1) * perPage + (data.index + 1) }}
      </template>
      <template #cell(createdAt)="data">
          {{data.item.updatedAt_cast}}
      </template>
      <template #cell(cost)="data">
          {{data.item.costTax_cast}}
      </template>
      <template #cell(name)="data">
          {{data.item.status == 'canceled' ? 'Pengembalian dana campaign ' + data.item.name : 'Campaign ' + data.item.name,}}
      </template>
      <template #cell(action)="data">
      </template>
      <template #empty="scope">
        <h5 class="text-center">Tidak Ada Data</h5>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label=""
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <!-- <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        /> -->
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
          @change="changePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    
  </b-card>
</template>

<script>
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BCard, BFormFile
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import { EventBus } from "@/eventBus.js";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormFile
  },
  directives: {
      Ripple,
  },
  props: [
    "names",
    "filtering"
  ],
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'id',
      tamp:'desc',
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      filters:'',
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'no', label: 'No' },
        { key: 'createdAt', label: 'Tanggal' ,sortable:true },
        { key: 'cost', label: 'Nominal Pembayaran', sortable:true },
        { key: 'name', label: 'Deskripsi' },
        // {key: 'action', label: 'Aksi', thStyle: 'width:140px'}
      ],
      /* eslint-disable global-require */
      items: [],
      terminID: null,
      file: '',
      name:'',
      dateTo:'',
      dateFrom:'',
      configs:'',
      urls:'',
      cur_page:1
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    EventBus.$on("filters", () => {
      console.log(this.filtering);
      this.dateFrom = this.filtering.dateFrom
      this.dateTo = this.filtering.dateTo
      this.getListHistoryTransaction()
    });

    if (!EventBus._events['export_riwayat']) {
        EventBus.$on('export_riwayat', () => {
            this.DownloadReporting();
        })
    }

    EventBus.$on("resetfFlter", () => {
      this.resetFilters();
    });
    
    this.getListHistoryTransaction()

  },
  methods: {
    sortManual(e) {
      this.sortBy = e.sortBy;
      this.sortDesc = e.sortDesc == true
      this.tamp =  this.sortDesc == true ? 'desc' : 'asc'
      this.getListHistoryTransaction()
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getListHistoryTransaction(name="",page=1) {

      let orderBy = this.tamp
      let result = this.sortBy + "." + orderBy

      if (isNaN(this.name)) {
        this.configs = '/advertiser/transactions/filter?page=' + page + "&sortBy=" + result + '&name=' + this.name + '&dateFrom=' + this.dateFrom + '&dateTo=' + this.dateTo
        // this.configs = '/advertiser/transactions/filter?page=' + page + '&name=' + this.name + '&dateFrom=' + this.dateFrom + '&dateTo=' + this.dateTo
        this.urls = this.configs
      }else{
        this.configs = '/advertiser/transactions/filter?page=' + page + "&sortBy=" + result + '&cost=' + this.name + '&dateFrom=' + this.dateFrom + '&dateTo=' + this.dateTo
        // this.configs = '/advertiser/transactions/filter?page=' + page + '&cost=' + this.name + '&dateFrom=' + this.dateFrom + '&dateTo=' + this.dateTo
        this.urls = this.configs
      }
      this.urls = this.configs
      axios.get(this.configs)
        .then(resp => {
          let _ = this
          let termin = resp.data.data
          // let data = termin.data.map((v,i) => {
          //   let file = v.attachment ? v.attachment.split('/') : '-'
          //   return {
          //         id: v.id,
          //         // no: parseInt(i)+parseInt(1),
          //         createdAt: v.updatedAt_cast,
          //         cost: v.costTax_cast,
          //         name: v.status == 'canceled' ? 'Pengembalian dana campaign ' + v.name : 'Campaign ' + v.name,

          //     }
          // })

          _.items = termin.data
          _.totalRows = termin.totalData
          _.perPage = 10
          _.currentPage = termin.currentPage
        })
        .catch(err => {
          console.log(err.resp)
        })
    },
    handleFileUpload(){
      console.log('handleFileUpload')
      console.log(this.$refs.file.files[0]);
      this.file = this.$refs.file.files[0];
    },
    changePage(page) {
      console.log(page)
      this.cur_page = page
      this.getListHistoryTransaction(this.name,page)
    },
    resetFilters()
    {
      this.dateFrom = '',
      this.dateTo = '',
      this.getListHistoryTransaction()
    },

    DownloadReporting() {
      let _ = this;
      let url  = this.urls.replaceAll('/advertiser/transactions', '/advertiser/transactions/export')
      axios.get(url, { responseType: "arraybuffer" }).then((response) => {
        if (response) {
          let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "List Riwayat Transaksi" + new Date();
          link.click();
        }
      });
    },

  },
  watch:{
    names:function (newValue) {
      let _ = this
      this.name = newValue
      _.getListHistoryTransaction(this.name)
    },
  }
}
</script>
